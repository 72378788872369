import logo from "../Assets/WhatsApp_Image_2023-10-05_at_17.25 1.svg";
import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import AnchorLink from "react-anchor-link-smooth-scroll";



function NavbarBlack() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null);
  
    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };
  
    const closeMenu = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };
  
    useEffect(() => {
      document.addEventListener("click", closeMenu);
      return () => {
        document.removeEventListener("click", closeMenu);
      };
    }, []);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [isSearchOpen, setSearchOpen] = useState(false);
  
    const handleDropdownToggle = () => {
      setDropdownOpen(!isDropdownOpen);
      setSearchOpen(false); // Close the search input when opening the dropdown
    };
  
    const handleSearchToggle = () => {
      setSearchOpen(!isSearchOpen);
      setDropdownOpen(false); // Close the dropdown when opening the search input
    };
  return (
    <nav>
  <Link className="navlog" to='/'>

<img src={logo} alt="Logo" />
<p className="name">Mindfinia Consulting</p>
</Link>
      <div
        className={`hamburger ${isMenuOpen ? "open" : ""}`}
        onClick={toggleMenu}
      >
        &#9776;
      </div>
      <ul className={isMenuOpen ? "open" : ""}>
       
        <li class="dropdown">

        <Link className="liktabs" to='/WhoWeAre'>

          <a href="#" className="btn" style={{color:"black" , textDecoration:'none'}}>
           Who we are?
          </a>
        </Link>
        <ul class="dropdown-content">
            <div className="ul-containt">
              <div className="ul-containt-wo ul-containt-wo2">


                {/* <AnchorLink className="caselink" href="#About"> */}
              <Link className="caselink" to="/WhoWeAre?section=About"> 
                <li>
              About Us

                  {/* <Link to="/introduction"></Link> */}
               

                </li>
              </Link>
                {/* </AnchorLink> */}
                {/* <AnchorLink className="caselink" href="#Vision"> */}
              <Link className="caselink" to="/WhoWeAre?section=Vision">
                <li>
               Our Vision

              
                  {/* <Link to="/casestudies">Case Studies</Link> */}
                </li>
               </Link>
                {/* </AnchorLink> */}
                {/* <AnchorLink className="caselink" href="#Mission"> */}
              <Link className="caselink" to="/WhoWeAre?section=Mission">
                <li>
              Our Mission

                
                
                  {/* <Link to="/blog"></Link> */}
                </li>
              </Link>
                {/* </AnchorLink> */}
             
              </div>
              
             
            </div>
          </ul>

        </li>
        <li class="dropdown">
        <Link className="liktabs" to='/WhatWeDo'>

          <a href="#" className="btn" style={{color:"black" , textDecoration:'none'}}>
           What we do?
          </a>
          </Link>
          <ul class="dropdown-content">
            <div className="ul-containt">
              <div className="ul-containt-wo ul-containt-wo2">
              <Link className="caselink" to="/WhatWeDo?section=Introduction">
                <li>
              Introduction

                  {/* <Link to="/introduction">Introduction</Link> */}
                </li>
              </Link>
                {/* <AnchorLink className="caselink" href="#course"> */}
              <Link className="caselink" to="/WhatWeDo?section=course">
                <li>
              Case Studies

                
                  {/* <Link to="/casestudies">Case Studies</Link> */}
                </li>
              </Link>
                {/* </AnchorLink> */}
                {/* <AnchorLink className="caselink" href="#Blogs"> */}
              <Link className="caselink" to="/WhatWeDo?section=Blogs"> 
                <li>
              
               Blogs

                
                  {/* <Link to="/blog"></Link> */}
                </li>
               </Link>
                {/* </AnchorLink> */}
             
              </div>
              
             
            </div>
          </ul>
        </li>
        <li class="dropdown">
        <Link className="liktabs" to='/Solution'>

          <a href="#" className="btn" style={{color:"black" , textDecoration:'none'}}>
           Solution
          </a>
        </Link>

        <ul class="dropdown-content">
            <div className="ul-containt">
              <div className="ul-containt-wo ul-containt-wo2">
                {/* <AnchorLink className="caselink" href="#Products"> */}
              <Link className="caselink" to="/Solution?section=Products">
                <li>
              Products

          

                  {/* <Link to="/products"></Link> */}
                </li>
              </Link>
                {/* </AnchorLink> */}
                {/* <AnchorLink className="caselink" href="#Services"> */}
               <Link className="caselink" to="/Solution?section=Services">
                <li>
               
               Services

                  {/* <Link to="/services"></Link> */}
                </li>
               </Link>
               {/* </AnchorLink> */}
               
             
              </div>
              
             
            </div>
          </ul>
        </li>
        <li class="dropdown">
        <Link className="liktabs" to='/ContactUs'>

          <a href="#" className="btn" style={{color:"black" , textDecoration:'none'}}>
           Contact Us
          </a>
        </Link>
        
        </li>
        
  
     
      
        
      </ul>
    </nav>
  )
}

export default NavbarBlack