import React, { useEffect } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import NavbarBlack from "../Components/NavbarBlack";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import AOS from "aos";

function Contact() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <>
     <Navbar/>
      <div className="banner3 banner5">
      <h2 data-aos="fade-right">Take first step<br/>
towards success!</h2>
     <br/>
      <p data-aos="fade-right">
      Ready to elevate your business?<br/>
Reach out to us now and let's create<br/>
innovative solutions that propel your growth.
      </p>

      </div>
      {/* <div className="contact-heading">
        <h1>
          Take first step <br />
          towards success!
        </h1>
        <p>
          Ready to elevate your business?
          <br />
          Reach out to us now and let's create
          <br />
          innovative solutions that propel your growth.
        </p>
      </div> */}
      <div className="contact">
        <div className="contact-left">
          <h2>Find Your Ideal Digital Transformation Partner!</h2>
          <br/>
          <p>
          "Mindfinia" combines intellectual expertise ("Mind") with limitless technological possibilities ("Finia"), encapsulating a promise of strategic insight and boundless solutions for technology consulting and services
          </p>
        </div>
        <div className="contact-right">
       {/* <div className="contact-form">
       <TextField id="standard-basic" label="First Name" variant="standard" />
       <TextField id="standard-basic" label="Last Name" variant="standard" />

       </div>
       <br/>
       <br/>
     
       <div className="contact-form">
       <div class="input-field">
    <input type="text"  className="input" required />
    <label for="name">Email</label>
  </div>
  <div class="input-field">
    <input type="number"  className="input" required />
    <label for="name">Mobile No.</label>
  </div>

       </div>
     
       <br/>
       <br/>
     

       <div className="contact-forma">
       <input placeholder="Company Name"/>
       

       </div>
       <br/>
<br/>

       <div className="contact-form">
        <textarea className="textarea" placeholder="Query">
          
        </textarea>
       </div> */}
       <Box
      component="form"
      noValidate
      autoComplete="off"
      style={{ display:'flex' , justifyContent:'space-between'}}

    >
      <TextField
        id="standard-basic"
        label="First Name"
        variant="standard"
        style={{ width: '45%' }}
      />
      <TextField
        id="standard-basic"
        label="Last Name"
        variant="standard"
        style={{ width: '45%' }}
      />
    </Box>
    <br/>
    <Box 
      component="form"
      noValidate
      autoComplete="off"
      style={{ display:'flex' , justifyContent:'space-between'}}

    >
      <TextField
        id="standard-basic"
        label="Email"
        variant="standard"
        style={{ width: '45%' }}
      />
      <TextField
        id="standard-basic"
        label="Mobile No."
        variant="standard"
        style={{ width: '45%' }}
      />
    </Box>
    <br/>
    <Box 
      component="form"
    
      noValidate
      autoComplete="off"
    >
     
      <TextField fullWidth id="standard-basic" label="Company Name" variant="standard" />
      

    </Box>
    <br/>
    <Box
      component="form"
  
      noValidate
      autoComplete="off"
    >
     
      <TextField fullWidth id="standard-basic" label="Query" variant="standard" />
      

    </Box>
    <br/>
       <div className="center">
<button className="sendcontact" data-aos="zoom-out-down">Submit</button>
       </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Contact;
