import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'


function Services() {
  return (
    <>
        <Navbar/>
      

        <Footer/>
    </>
  )
}

export default Services