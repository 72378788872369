import React, { useEffect } from "react";
import AOS from "aos";
import Navbar from '../Components/Navbar'
import img1 from '../Assets/young-employees-sitting-office-table-using-laptop-team-work-brainstorming-meeting-concept 1.svg'
import { useState } from 'react'
import icon from '../Assets/Icon11.svg'
import mail from '../Assets/mail.svg'
import Footer from '../Components/Footer'


function Career() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
    const [activeTab, setActiveTab] = useState(1);
      
    const handleTabChange = (tabIndex) => {
      setActiveTab(tabIndex);
    };
  return (

    <>
        <Navbar/>
        <div className="about-section">
        <h1>Products</h1>
        <br />
        <p>
          MindFinia is not just a name; it's a promise and a philosophy. We are
          a dynamic and innovative company
          <br />
          specializing in the realms of data, analytics, and artificial
          intelligence. Our name, a fusion of 'Mind' and 'Infinity,'
          <br />
          reflects our core belief in the limitless potential of human intellect
          and the endless possibilities of data.
        </p>

        <br />
        <br />

        <img id='abt-banner' src={img1} />
      </div>
      <br/>
<div className='center'> 
<div className='line'></div>
<br/>

<h1 className='al'>Career Opportunities</h1>
</div>   
<br/>


<div className="tab-container">
<div className="tabs-container">
  <div className="tabs">
    <button
      className={activeTab === 1 ? 'tab-btn active' : 'tab-btn'}
      onClick={() => handleTabChange(1)}
    >
      Marketing
    </button>
    <button
      className={activeTab === 2 ? 'tab-btn active' : 'tab-btn'}
      onClick={() => handleTabChange(2)}
    >
      Development 
    </button>
    <button
      className={activeTab === 3 ? 'tab-btn active' : 'tab-btn'}
      onClick={() => handleTabChange(3)}
    >
      Product 
    </button>
  </div>
</div>

      <div className="tab-content">
        {activeTab === 1 && <p>
       <h1>
       Copywriter (Intermediate)
       </h1>
       <h3>San Francisco, CA   |  Part Time</h3>
     <div className="apply">
     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been <br/>
       the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type <br/>
       and scrambled it to make a type specimen book.</p>
       <button>Apply Now</button>
     </div>
        </p>
      }
        {activeTab === 2 && <p className='tb2'>
            <h1>
           tab2
            </h1>
            </p>

          

         
        }
        {activeTab === 3 && <p className='tb2'>
            <h1>
           tab3
            </h1>
            </p>

          

         
        }
        
      </div>
    </div>
    <div className='center'>
    <img src={icon}/>
    <br/>
    <p>Contact Us</p>
    <br/>
<h2 className="al">Let’s build something awesome together</h2>

    </div>
    <br/>
    <br/>
<div className='box-section'>
<div className='box'>
<button>Contact</button>
<br/>
<div className='hr'></div>


<div className='mail'>
<img src={mail}/>
<div>
<h4>Email</h4>
<br/>

<p>Sales@mindfinia.com</p>
</div>
</div>
     
</div>
<div className='box'>
<button>Contact</button>
<br/>
<div className='hr'></div>


<div className='mail'>
<img src={mail}/>
<div>
<h4>Email</h4>
<br/>

<p>Sales@mindfinia.com</p>
</div>
</div>
     
</div>
<div className='box'>
<button>Contact</button>
<br/>
<div className='hr'></div>


<div className='mail'>
<img src={mail}/>
<div>
<h4>Email</h4>
<br/>

<p>Sales@mindfinia.com</p>
</div>
</div>
     
</div>
     
</div>
<br/>
<br/>

<div className='form-section'>
<div className='form'>
<div className='form-row'>
    <div className='inputs'>
    <lable className='lable'>Full Name*</lable>
    <input/>

    </div>
    <div className='inputs'>
    <lable className='lable'>Full Name*</lable>
    <input/>

    </div>
</div>
<br/>
<br/>
<div className='form-row'>
    <div className='inputs'>
    <lable className='lable'>Full Name*</lable>
    <input/>

    </div>
    <div className='inputs'>
    <lable className='lable'>Full Name*</lable>
    <input/>

    </div>
</div>
<br/>
<br/>
<div className='message'>
<lable className='lable'>Full Name*</lable>
    <input/>
</div>
<br/>
<br/>
<div className='center'>
<button>Send Message</button>

</div>

</div>

</div>
<br/>
<br/>
<Footer/>
 </>
  )
}

export default Career