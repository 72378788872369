import React from "react";
import Footer from "../Components/Footer";
import img from '../Assets/blg.jpg'
import NavbarBlack from "../Components/NavbarBlack";

function BlogDetail() {
  return (
    <>
      <NavbarBlack/>
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="center">
        <br />
        <br />
        <div className="line"></div>
        <h1>Blog Name</h1>
        <br />

        <h3>Latest Blogs</h3>
      </div>
      <br />
      <br />
      <div className="blog-detail">
       <div className="blog-detail-left">
       <img src={img}/>
       <br/>
       <br/>
       <h1>Blog Name</h1>
        <p>
          Mindfinia is a dynamic digital transformation, Salesforce
          consulting and development powerhouse, dedicated to empowering
          businesses to unleash the full potential of the Salesforce platform.
          With a comprehensive suite of cutting-edge services, we enable
          organizations to embark on seamless Salesforce implementation,
          tailor-made customization, seamless integration, streamlined
          migration, and unwavering support. We are your trusted partner in
          harnessing the transformative power of Salesforce to drive
          unprecedented growth, enhance operational efficiency, and achieve
          unrivaled success.
        </p>
        <h3>Created by</h3>
        <button>Continue Reading</button>

       </div>
       <div  className="blog-detail-right">
  
       </div>
      </div>

      <Footer />
    </>
  );
}

export default BlogDetail;
