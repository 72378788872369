import React, { useEffect } from "react";
import { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Navbar from "../Components/Navbar";
import banner from "../Assets/g12.svg";
import about from "../Assets/image 2.svg";
import log from "../Assets/logo (1).svg";
import log2 from "../Assets/logo (3).svg";
import log4 from "../Assets/logo (4).svg";
import log5 from "../Assets/logo (5).svg";
import log6 from "../Assets/logo (6).svg";
import sys1 from "../Assets/syn logo (1).svg";
import sys2 from "../Assets/syn logo (2).svg";
import sys3 from "../Assets/syn logo (3).svg";
import why from "../Assets/image 3.svg";
import tech1 from "../Assets/technology (1).svg";
import tech2 from "../Assets/technology (2).svg";
import tech3 from "../Assets/technology (3).svg";
import tech4 from "../Assets/technology (4).svg";
import tech5 from "../Assets/technology (5).svg";
import lang1 from "../Assets/lang (1).svg";
import lang2 from "../Assets/lang (2).svg";
import lang3 from "../Assets/lang (3).svg";
import lang4 from "../Assets/lang (4).svg";
import comma1 from "../Assets/fluent_comma-24-regular.svg";
import comma2 from "../Assets/fluent_comma-24-regular-1.svg";
import profile from "../Assets/profile.svg";
import star from "../Assets/srart.svg";
import Footer from "../Components/Footer";
import AOS from "aos";
import NavbarBlack from "../Components/NavbarBlack";
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';

function Home() {
  const [counterOn, setCounterOn] =useState(false)
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  const percentCardsRef = useRef(null);

  useEffect(() => {
    const percentCards = percentCardsRef.current;

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // Section is in view, add class to trigger animation
          percentCards.classList.add('animate');
        } else {
          // Section is out of view, remove class
          percentCards.classList.remove('animate');
        }
      });
    });

    // Start observing the section
    if (percentCards) {
      observer.observe(percentCards);
    }

    // Cleanup observer on component unmount
    return () => {
      if (percentCards) {
        observer.unobserve(percentCards);
      }
    };
  }, [percentCardsRef]);

  
  
  return (
    <>
     <Navbar/>
      <div className="banner-section">
        <div className="banner-section-left" data-aos="fade-right">
          <h1>
            <span>"Mindfinia"</span> combines intellectual expertise ("Mind")
            with limitless technological possibilities ("Finia"), encapsulating
            a promise of strategic insight and boundless solutions for
            technology consulting and services
          </h1>
        </div>
        <div data-aos="fade-down-left" className="banner-section-right">{/* <img src={banner}/> */}</div>
      </div>
      <div className="slider">
        {/* <ul>
          <span>PTABILITY</span>
          <span>EXPERTISE</span>
          <span>INTEGRITY</span>
          <span>IMPROVEMENT</span>
          <span>QUALITY</span>
          <span>CUSTOMER</span>
          <span>SATISFACTION</span>
          <span>TRANSPARENCY</span>
          <span>COLLABORATION</span>
          
        </ul> */}


       

<div class="marquee">
  <div class="marquee--inner">
    <span className="span">
      <div className="sss"> 
      <span>PTABILITY</span>
          <span>EXPERTISE</span>
          <span>INTEGRITY</span>
          <span>IMPROVEMENT</span>
          <span>QUALITY</span>
          <span>CUSTOMER</span>
          <span>SATISFACTION</span>
          <span>TRANSPARENCY</span>
          <span>COLLABORATION</span>&nbsp;</div>
    </span>
    <span>
      <div className="sss">  <span>PTABILITY</span>
          <span>EXPERTISE</span>
          <span>INTEGRITY</span>
          <span>IMPROVEMENT</span>
          <span>QUALITY</span>
          <span>CUSTOMER</span>
          <span>SATISFACTION</span>
          <span>TRANSPARENCY</span>
          <span>COLLABORATION</span>&nbsp;</div>
    </span>
  </div>
</div>
      </div>
      <br/>
      <br/>

      <div className="about">
        <div className="about-left" data-aos="fade-right">
          <div className="line" data-aos="flip-left" data-aos-duration="4000"></div>
         
          <h1>About Mindfinia</h1>
          <br />
      
          <p>
            Welcome to Mindfinia, a visionary force reshaping the
            landscape of Digital Transformation, CRM, and Cloud Consulting. We
            ignite a global revolution in IT Services, forging strategic
            alliances with industry giants like Salesforce, AWS, Google Cloud,
            and Dynamics 365. Our mission? To empower businesses with the tools
            and strategies they need to not only thrive but surpass all
            expectations, delivering unprecedented growth on a global scale.
          </p>
         
      
        </div>
        <div className="about-right">
          <img src={about}  data-aos="fade-down-left"/>
        </div>
      </div>
      <div className="center" style={{textAlign:'center'}}>
      <br />
      <br />
    
       

          <div className="line" data-aos="flip-left" data-aos-duration="4000"></div>
          <h2 className="meetpeople">
            <span> Meet the people</span>
            <br />
            We are Working With
          </h2>
      </div>
      <br/>
      <div className="brand-slider-section">
        <div className="brand-slider">
          <img src={log}/>
          <img src={log2}/>
          <img src={log4}/>
          <img src={log5}/>
          <img src={log6}/>
          {/* <img src={log}/> */}
        </div>
      </div>
      {/* <div className="offer">
        <br />
        <br />
        <div className="line" data-aos="flip-left">
          {" "}
        </div>

        <h1>Services we offer</h1>
        <br />
        <br />

        <div className="ser-cards">
          <div className="ser-cards-row">
            <div className="ser-card">
              <div className="sys">
                <img src={sys1} />
              </div>
              <br />

              <h1>Mobile App Development</h1>
              <br />

              <p>
                A Website is an extension of yourself and we can help you to
                express it properly. Your website is your number one marketing
                asset because we live in a digital age.
              </p>
            </div>
            <div className="ser-card">
              <div className="sys">
                <img src={sys1} />
              </div>
              <br />

              <h1>Mobile App Development</h1>
              <br />

              <p>
                A Website is an extension of yourself and we can help you to
                express it properly. Your website is your number one marketing
                asset because we live in a digital age.
              </p>
            </div>
          </div>
          <div className="ser-cards-row">
            <div className="ser-card">
              <div className="sys">
                <img src={sys1} />
              </div>
              <br />

              <h1>Mobile App Development</h1>
              <br />

              <p>
                A Website is an extension of yourself and we can help you to
                express it properly. Your website is your number one marketing
                asset because we live in a digital age.
              </p>
            </div>
            <div className="ser-card">
              <div className="sys">
                <img src={sys1} />
              </div>
              <br />

              <h1>Mobile App Development</h1>
              <br />

              <p>
                A Website is an extension of yourself and we can help you to
                express it properly. Your website is your number one marketing
                asset because we live in a digital age.
              </p>
            </div>
          </div>
        </div>
      </div> */}

      <div className="about">
        <div className="about-left" data-aos="fade-right">
          <div className="line" data-aos="flip-left" data-aos-duration="4000"></div>
     
          <h1>Why Choose Us</h1>
          <br />
      
          <h3>
            
          </h3>
     
          <p>
          UNMATCHED EXPERTISE, EMPOWERING SCALABILITY, & IRRESISTIBLE
            COMPETITIVE PRICING
            <br/>
            Mindfinia is a dynamic digital transformation,
            Salesforce consulting and development powerhouse, dedicated to
            empowering businesses to unleash the full potential of the
            Salesforce platform. With a comprehensive suite of cutting-edge
            services, we enable organizations to embark on seamless Salesforce
            implementation, tailor-made customization, seamless integration,
            streamlined migration, and unwavering support. We are your trusted
            partner in harnessing the transformative power of Salesforce to
            drive unprecedented growth, enhance operational efficiency, and
            achieve unrivaled success.
          </p>
        
        </div>
        <div className="about-right" data-aos="fade-left">
          <img src={why}/>
        </div>
      </div>
      <ScrollTrigger onEnter={()=>setCounterOn(true)} onExit={()=>setCounterOn(false)}>
      <div className="persent-sectiom" ref={percentCardsRef}>
      <div className="persent-crds" data-aos="fade-right">
        <div className="circle">
        <h2 className="cont">

<CountUp start={0} end={100} duration={3} redraw={true}>
  {({ countUpRef }) => (
    <h2 ref={countUpRef} />
  )}
</CountUp>
%
</h2>
        </div>
        <br />
        <h3>PROFICIENCY</h3>
     
        <p>
          Mindfinia expertise spans consulting, development,
          integration, delivering tailored high-quality solutions to meet
          specific industry needs.
        </p>
      </div>
      <div className="persent-crds" data-aos="zoom-out">
        <div className="circle">
        <h2 className="cont">

          <CountUp start={0} end={100} duration={3} redraw={true}>
            {({ countUpRef }) => (
              <h2 ref={countUpRef} />
            )}
          </CountUp>
          %
        </h2>

        </div>
        <br />
        <h3>Skillfulness</h3>
       
        <p>
          Mindfinia expertise spans consulting, development,
          integration, delivering tailored high-quality solutions to meet
          specific industry needs.
        </p>
      </div>
      <div className="persent-crds" data-aos="fade-left">
        <div className="circle">
        <h2 className="cont">

<CountUp start={0} end={100} duration={3} redraw={true}>
  {({ countUpRef }) => (
    <h2 ref={countUpRef} />
  )}
</CountUp>
%
</h2>
        </div>
        <br />
        <h3>Efficiency</h3>
      
        <p>
          Mindfinia expertise spans consulting, development,
          integration, delivering tailored high-quality solutions to meet
          specific industry needs.
        </p>
      </div>
    </div>
</ScrollTrigger>

<br />     <br />

      <div className="ourcustomer">
        <br />
        <br />
        <div className="line" data-aos="flip-left" data-aos-duration="4000"></div>
   
        <h1>
         Our Valued Customer

        </h1>
        <br />
        <br />
       

<div class="marquee">
  <div class="marquee--inner">
    <span className="span">
      <div className="sss">
      <img src={tech1} />
          <img src={tech2} />
          <img src={tech3} />
          <img src={tech4} />
          <img src={tech5} />
          <img src={lang1} />
          <img src={lang2} />
          <img src={lang3} />
          <img src={lang4} />
      &nbsp;</div>
    </span>
    <span>
      <div className="sss">
      <img src={tech1} />
          <img src={tech2} />
          <img src={tech3} />
          <img src={tech4} />
          <img src={tech5} />
          <img src={lang1} />
          <img src={lang2} />
          <img src={lang3} />
          <img src={lang4} />
      &nbsp;</div>
    </span>
  </div>
</div>
     
        {/* <div className="lang scrolling-images">
          <img src={tech1} />
          <img src={tech2} />
          <img src={tech3} />
          <img src={tech4} />
          <img src={tech5} />
          <img src={lang1} />
          <img src={lang2} />
          <img src={lang3} />
          <img src={lang4} />
        </div> */}
        <br />
   
       
       
      </div>
      <br/>
    
      <div className="testimonials">
        <div className="line" data-aos="flip-left" data-aos-duration="4000"></div>
      

        <h1>
          <span>Why cutomers love</span>
          <br />
          working with us
        </h1>
        <br />
        <br />
        <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
       <div className="test">
      <div className="test1">
      <div className="review">
          <img className="coma1" src={comma2} />

          <p>
            Without any doubt I recommend Alcaline Solutions as one of the best
            web design and digital marketing agencies. One of the best agencies
            I’ve came across so far. Wouldn’t be hesitated to introduce their
            work to someone else.
          </p>

          <img className="coma2" src={comma1} />
        </div>

        <div className="review-profile">
          <div className="review-profile-card">
            <img src={profile} />
            <img src={star} />
            <h3>Romeena De Silva</h3>
            <p>Janet Cosmetics</p>
          </div>
        </div>
      </div>
       </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="test">
      <div className="test1">
      <div className="review">
          <img className="coma1" src={comma2} />

          <p>
            Without any doubt I recommend Alcaline Solutions as one of the best
            web design and digital marketing agencies. One of the best agencies
            I’ve came across so far. Wouldn’t be hesitated to introduce their
            work to someone else.
          </p>

          <img className="coma2" src={comma1} />
        </div>

        <div className="review-profile">
          <div className="review-profile-card">
            <img src={profile} />
            <img src={star} />
            <h3>Romeena De Silva</h3>
            <p>Janet Cosmetics</p>
          </div>
        </div>
      </div>
       </div>
        </SwiperSlide>
        <SwiperSlide>Slide 3</SwiperSlide>
        <SwiperSlide>Slide 4</SwiperSlide>
        <SwiperSlide>Slide 5</SwiperSlide>
        <SwiperSlide>Slide 6</SwiperSlide>
        <SwiperSlide>Slide 7</SwiperSlide>
        <SwiperSlide>Slide 8</SwiperSlide>
        <SwiperSlide>Slide 9</SwiperSlide>
      </Swiper>
       
        
        <br />
        <br />
      </div>
      <Footer />
    </>
  );
}

export default Home;
