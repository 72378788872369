import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { animateScroll as scroll, scroller } from 'react-scroll';
import about2 from '../Assets/about banners (1).svg'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import about1 from '../Assets/about banners (2).svg'
import about3 from '../Assets/about banners (3).svg'
import icon from '../Assets/Icon.svg'

import AOS from "aos";


function About() {
        useEffect(() => {
                window.scrollTo(0, 0);
              }, []);
        const location = useLocation();
        useEffect(() => {
                AOS.init({ duration: 2000 });
              }, []);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sectionId = params.get('section');

    if (sectionId) {
      // Scroll to the specified section
      scroller.scrollTo(sectionId, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    } else {
      // Scroll to the top if no section is specified
      scroll.scrollToTop();
    }
  }, [location.search]);
  return (
    <>
        <Navbar/>
        <div className="bnnar">
        <h1 data-aos="fade-right">About <br/>Mindfinia</h1>
        <br/>
        <p data-aos="zoom-out">MindFinia is not just a name; it's a promise and a philosophy. We are a dynamic and innovative company specializing in the realms of data, analytics, and artificial intelligence. Our name, a fusion of 'Mind' and 'Infinity,' reflects our core belief in the limitless potential of human intellect and the endless possibilities of data.
</p>
 <br/>



        </div>

        {/* <div className='about-section'>
        <h1>About Mindfinia</h1>
        <br/>
<p>MindFinia is not just a name; it's a promise and a philosophy. We are a dynamic and innovative company specializing in the realms of data, analytics, and artificial intelligence. Our name, a fusion of 'Mind' and 'Infinity,' reflects our core belief in the limitless potential of human intellect and the endless possibilities of data.
</p>
<br/>
<p>At MindFinia, we are a team of dedicated experts, visionaries, and technologists, united by a common mission: to unlock the infinite power of the human mind and harness it in synergy with cutting-edge technology.
</p>
        <br/>
        <br/>

<img id='abt-banner' className="newstatic" src={about1}/>

        </div> */}
        <br/>
        <div className='about-who-we-are'>
        <br/>
        <br/>

<div className='line'  data-aos="flip-left" data-aos-duration="4000">

</div>
        <br/>

<h1 id="About">Who We Are?</h1>
<br/>

<p style={{paddingLeft:"30px" , paddingRight:'30px' , textAlign:'left'}}>We are the bridge between 'Mind' and 'Infinity,' where 
imagination knows no bounds, where data holds the key to unlocking untapped potential, and where the future is limited only by the extent of our collective vision. We are MindFinia, and we are here to reshape the world of data, analytics, and AI, driving unparalleled success for those who embark on this exciting journey with us.</p>
<br/>

        </div>
  

<br/>
<br/>

<div className="R-vission" id="Vision" >
<div className="R-vissioni-img">

<h1 data-aos="zoom-in">Our Vision</h1>
<br/>
<p>At MindFinia, we envision a future where the boundless potential of the human mind converges with the infinite possibilities of data, analytics, and artificial intelligence. Our relentless commitment is to lead a transformation in the world of consulting, catalyzing change in the Data, Analytics, and AI space. </p>
        
</div>
<div className="R-vission-text">
<div className="R-vission-text-left">
<img className="icn" src={icon} data-aos="zoom-out-up"/>
<br/>
<h1>Transformation In The World</h1>

<br/>
<p>Our relentless commitment is to lead a transformation in the world of consulting, catalyzing change in the Data, Analytics, and AI space. We aspire to be the guiding light that empowers organizations to harness the full spectrum of their data, explore the uncharted territories of analytics, and journey to the horizon of limitless innovation. Our vision for MindFinia is to break free from conventional thinking, ushering in a new era where the fusion of human intelligence and technological advancement propels us toward endless horizons.</p>
        
</div>
<div className="R-vission-text-right" >
<img className="icn" src={icon} data-aos="zoom-out-up"/>
<br/>
<h1>Profound Dedication</h1>
<br/>
<p>With a profound dedication to excellence, we strive to create a future where every business, every decision, and every insight is touched by the infinite possibilities of the mind and the boundless potential of data, driving a new standard for success.


At MindFinia, we are committed to illuminating the path to a future where 'Mind meets Infinity,' and together, we redefine what's achievable in the world of Data, Analytics, and AI. Join us in this exciting journey of transformation, where the possibilities are as limitless as the human mind itself</p>
        
</div>


        
</div>




        
</div>
<br/>
<div className="R-vission " id="Mission">
<div className="R-vissioni-img R-mission">

<h1 data-aos="zoom-in">Our Mission</h1>
<br/>
<p data-aos="zoom-in-up">At MindFinia, our mission is to unlock the infinite possibilities of the human mind and channel its creative potential into the Data, Analytics, and AI space, redefining the very fabric of consulting in this domain. We believe that the convergence of 'Mind' and 'Infinity' holds the key to transforming businesses, industries, and society.</p>
        
</div>
<div className="R-vission-text">
<div className="R-vission-text-left">
<img className="icn" src={icon} data-aos="zoom-out-up"/>
<br/>
<h1>Our pledge</h1>

<br/>
<p>Our pledge is to foster a culture of boundless curiosity, continuous learning, and innovation, transcending traditional boundaries in the world of data and intelligence. We are dedicated to pushing the frontiers of what is conceivable, delivering solutions that harness the collective genius of our team and the latent potential within our clients. We commit to providing the finest services, insights, and technologies that empower organizations to transcend their limitations, solve complex challenges, and unearth opportunities that were previously hidden from view. Our approach is rooted in the belief that the human mind, when paired with advanced data, analytics, and AI, can achieve remarkable feats that were once thought impossible</p>
        
</div>
<div className="R-vission-text-right">
<img className="icn" src={icon} data-aos="zoom-out-up"/>
<br/>
<h1>Our Aim</h1>
<br/>
<p>We aim to be the trusted partner on every journey, guiding our clients through the intricate landscape of data and intelligence, enabling them to reach new heights and seize the infinite opportunities that await. Together, we will rewrite the rules and chart a course to a future where 'Mind meets Infinity,' driving innovation, transformation, and unprecedented success for all those we serve.


At MindFinia, our mission is your mission: to explore, to discover, and to unlock the infinite potential of the human mind for a future where possibilities know no bound</p>
        
</div>


        
</div>




        
</div>
{/* <div className='our-vision-section'>
        <div className='our-vision'>
        <img className='about-bnner2' id='abt-banner' src={about2}/>
        <br/>
        <br/>
        <div className='line'>

        </div>
     
        <h1>Our Vision</h1>
        <br/>
        <p>At MindFinia, we envision a future where the boundless potential of the human mind converges with the infinite possibilities of data, analytics, and artificial intelligence. Our relentless commitment is to lead a transformation in the world of consulting, catalyzing change in the Data, Analytics, and AI space.
We aspire to be the guiding light that empowers organizations to harness the full spectrum of their data, explore the uncharted territories of analytics, and journey to the horizon of limitless innovation. Our vision for MindFinia is to break free from conventional thinking, ushering in a new era where the fusion of human intelligence and technological advancement propels us toward endless horizons.
</p>
        <br/>
<p>With a profound dedication to excellence, we strive to create a future where every business, every decision, and every insight is touched by the infinite possibilities of the mind and the boundless potential of data, driving a new standard for success.

</p>
        <br/>
<p>At MindFinia, we are committed to illuminating the path to a future where 'Mind meets Infinity,' and together, we redefine what's achievable in the world of Data, Analytics, and AI. Join us in this exciting journey of transformation, where the possibilities are as limitless as the human mind itself</p>

</div>

        </div>
        <br/>

<br/>
<div className='our-vision-section' >
        <div className='our-vision'>
        <img className='about-bnner2' id='abt-banner' src={about3}/>
        <br/>
        <br/>
        <div className='line'>

        </div>
       
        <h1>Our Mission</h1>
        <br/>
        <p>At MindFinia, our mission is to unlock the infinite possibilities of the human mind and channel its creative potential into the Data, Analytics, and AI space, redefining the very fabric of consulting in this domain. We believe that the convergence of 'Mind' and 'Infinity' holds the key to transforming businesses, industries, and society.
Our pledge is to foster a culture of boundless curiosity, continuous learning, and innovation, transcending traditional boundaries in the world of data and intelligence. We are dedicated to pushing the frontiers of what is conceivable, delivering solutions that harness the collective genius of our team and the latent potential within our clients.
We commit to providing the finest services, insights, and technologies that empower organizations to transcend their limitations, solve complex challenges, and unearth opportunities that were previously hidden from view. Our approach is rooted in the belief that the human mind, when paired with advanced data, analytics, and AI, can achieve remarkable feats that were once thought impossible.
We aim to be the trusted partner on every journey, guiding our clients through the intricate landscape of data and intelligence, enabling them to reach new heights and seize the infinite opportunities that await. Together, we will rewrite the rules and chart a course to a future where 'Mind meets Infinity,' driving innovation, transformation, and unprecedented success for all those we serve.
</p>
        <br/>
<p>At MindFinia, our mission is your mission: to explore, to discover, and to unlock the infinite potential of the human mind for a future where possibilities know no bound
</p>
        <br/>

</div>

        </div> */}
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>

        <Footer/>
    </>
  )
}

export default About