import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { animateScroll as scroll, scroller } from 'react-scroll';
import AOS from "aos";
import Navbar from "../Components/Navbar";
import intro1 from "../Assets/intro1.svg";
import icon from "../Assets/csestudy.avif";
import headphone from "../Assets/scene-with-business-person-working-futuristic-office-job.jpg";
import google from "../Assets/google.svg";
import arrow from '../Assets/Vector (1).svg'
import cs2 from '../Assets/2151003750.jpg'
import youtube from '../Assets/youtube.svg'
import cs3 from '../Assets/2151003719.jpg'
import cs4 from '../Assets/4913.jpg'

import fb from '../Assets/fb.svg'
import Footer from "../Components/Footer";
import blogimg from '../Assets/blg.jpg'
import { Link } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader } from "reactstrap";


function Introduction() {
  const [modal , setmodal] = useState(false)
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sectionId = params.get('section');

    if (sectionId) {
      // Scroll to the specified section
      scroller.scrollTo(sectionId, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    } else {
      // Scroll to the top if no section is specified
      scroll.scrollToTop();
    }
  }, [location.search]);

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <>
      <Navbar />
      <div className="banner2" id="Introduction">
      <h1 data-aos="fade-right">About Mindfinia</h1>
      <br />
        <p data-aos="zoom-out">
          MindFinia is not just a name; it's a promise and a philosophy. We are
          a dynamic and innovative company specializing in the realms of data,
          analytics, and artificial intelligence. Our name, a fusion of 'Mind'
          and 'Infinity,' reflects our core belief in the limitless potential of
          human intellect and the endless possibilities of data.
        </p>
        <br />
        <p data-aos="zoom-out">
          At MindFinia, we are a team of dedicated experts, visionaries, and
          technologists, united by a common mission: to unlock the infinite
          power of the human mind and harness it in synergy with cutting-edge
          technology.
        </p>
        <br/>
        <br/>


      </div>
      
      {/* <div className="about-section">
        <h1>About Mindfinia</h1>
        <br />
        <p>
          MindFinia is not just a name; it's a promise and a philosophy. We are
          a dynamic and innovative company specializing in the realms of data,
          analytics, and artificial intelligence. Our name, a fusion of 'Mind'
          and 'Infinity,' reflects our core belief in the limitless potential of
          human intellect and the endless possibilities of data.
        </p>
        <br />
        <p>
          At MindFinia, we are a team of dedicated experts, visionaries, and
          technologists, united by a common mission: to unlock the infinite
          power of the human mind and harness it in synergy with cutting-edge
          technology.
        </p>
        <br />
        <br />

        <img id='abt-banner' className="newstatic" src={intro1} />
      </div> */}
     
      <div className="intro-section" id="course">
        <img className="icon" src={icon} data-aos="zoom-out" />
        <br />
     
        <div className="line" data-aos="flip-left"></div>
        <br />

        <h1 className="al" data-aos="zoom-out">Browse our case studies</h1>
      </div>

      <br />
      <div className="casestudy-section">
        <div className="casestudy">
          <div className="casestudy-left">
            <img src={headphone} data-aos="fade-right"/>
          </div>
          <div className="casestudy-right">
            {/* <img src={google} /> */}
            <br/>
       

            <h2 >
            Charting a Course to Data Excellence: Revolutionizing Travel E-Commerce with Collibra DQ and Datadog
            </h2>
            
            <p >
            In the vibrant world of travel and hospitality, data is not just numbers — it is the heartbeat of customer experiences and business success. Our client, a trailblazing e-commerce titan in the travel sector, navigated through a sea of data daily. With their infrastructure anchored in AWS and sails set towards a data mesh architecture, they faced turbulent waters: maintaining trust and integrity in their internal data flows.
            </p>
            <br/>
            <br/>
<Link className="caselink" to='/casestudies2'>

            <button data-aos="fade-left">Read case study<img src={arrow}/></button>
</Link>
          </div>
        </div>
      </div>
      <br/>
      <br />

      <div className="casestudy-section">
        <div className="casestudy" id="pink">
          <div className="casestudy-left">
            <img src={cs2} data-aos="fade-right"/>
          </div>
          <div className="casestudy-right">
            {/* <img src={youtube} /> */}
           

            <h2 >
            Mastering the Healthcare Data Universe: A Symphony of Azure Lakehouse, MDM, and Data Analysis
            </h2>
            
            <p >
            Embark with us on a visionary journey where data is not just bytes and bits, but the lifeblood of healthcare innovation. Our client, a healthcare behemoth, faced a daunting challenge: their data universe was a constellation of disjointed information, each star shining alone, lacking connection and context. The mission? To orchestrate a data revolution, not just by constructing an Azure-based Lakehouse but also by integrating Master Data Management (MDM) and Data Analysis, transforming this scattered starlight into a galaxy of coordinated, actionable insights.
            </p>
            <br/>
           
            <Link className="caselink" to='/casestudies'>
            <button data-aos="fade-left">Read case study<img src={arrow}/></button>
</Link>
          </div>
        </div>
      </div>
      <br/>
      <br />

      <div className="casestudy-section">
        <div className="casestudy" id="yellow">
          <div className="casestudy-left">
            <img src={cs3} data-aos="fade-right"/>
          </div>
          <div className="casestudy-right">
            {/* <img src={fb} /> */}
          

            <h2 >
            Charting Retail's Future: Harnessing AWS and Tableau for Transformative Dashboard Intelligence
            </h2>
            
            <p>
            Imagine a retail juggernaut, rich in data yet starved of insights. This was our client's paradox, standing amidst a goldmine of data but lacking the tools to mine it. They were adrift in a sea of information without a compass. Our challenge was to provide that compass — in the form of sophisticated, intuitive dashboards powered by AWS Cloud and Tableau, transforming data from raw figures into a strategic asset.
            </p>
            <br/>
            <br/>
            <Link className="caselink" to='/casestudies3'>
            <button data-aos="fade-left">Read case study<img src={arrow}/></button>
</Link>
          </div>
        </div>
      </div>
      <br />

      <br/>
      <div className="casestudy-section">
        <div className="casestudy" id="orange">
          <div className="casestudy-left">
            <img src={cs4} data-aos="fade-right"/>
          </div>
          <div className="casestudy-right">
     
          

            <h2 >
            Crafting the Future of Manufacturing: Azure and Power BI's Symphony in Data Mastery
            </h2>
            
            <p>
            Venture into the realm of modern manufacturing, a landscape where data flows as abundantly as materials on a production line. Here, our client, a titan in the manufacturing industry, grappled with a common yet daunting challenge: turning their vast data reservoirs into a source of actionable wisdom. Burdened by outdated systems and siloed data streams, they needed a transformative solution — a confluence of Azure's robust data management with Power BI’s innovative visualization prowess.
            </p>
            <br/>
            
            <Link className="caselink" to='/casestudies4'>
            <button data-aos="fade-left">Read case study<img src={arrow}/></button>
</Link>
          </div>
        </div>
      </div>
      <br/>

      <div className='center'>
        <br/>
        <br/>
        <div className='line' data-aos="flip-left" data-aos-duration="4000">

        </div>
<h1 id="Blogs" data-aos="zoom-out">Latest Blogs</h1>

        </div>
        <br/>
        <br/>
        {/* <div className='blog'>
  <div className='blog-card-container'>
   <div className='blog-card'>
   <Link to='./blogdetails' className='link'>
      <img src={blogimg} alt='Blog Image'/>
      <h2>Title Name</h2>
      <p>Description</p>
      <button>Read More</button>
   </Link>
    </div>
    <div className='blog-card'>
    <Link to='./blogdetails' className='link'>
      <img src={blogimg} alt='Blog Image'/>
      <h2>Title Name</h2>
      <p>Description</p>
      <button>Read More</button>
   </Link>
    </div>
    <div className='blog-card'>
    <Link to='./blogdetails' className='link'>
      <img src={blogimg} alt='Blog Image'/>
      <h2>Title Name</h2>
      <p>Description</p>
      <button>Read More</button>
   </Link>
    </div>
    <div className='blog-card'>
    <Link to='./blogdetails' className='link'>
      <img src={blogimg} alt='Blog Image'/>
      <h2>Title Name</h2>
      <p>Description</p>
      <button>Read More</button>
   </Link>
    </div>
    <div className='blog-card'>
    <Link to='./blogdetails' className='link'>
      <img src={blogimg} alt='Blog Image'/>
      <h2>Title Name</h2>
      <p>Description</p>
      <button>Read More</button>
   </Link>
    </div>
    <div className='blog-card'>
      <img src={blogimg} alt='Blog Image'/>
      <h2>Title Name</h2>
      <p>Description</p>
      <button>Read More</button>
    </div>
    <div className='blog-card'>
      <img src={blogimg} alt='Blog Image'/>
      <h2>Title Name</h2>
      <p>Description</p>
      <button>Read More</button>
    </div>
    <div className='blog-card'>
      <img src={blogimg} alt='Blog Image'/>
      <h2>Title Name</h2>
      <p>Description</p>
      <button>Read More</button>
    </div>
  </div>
</div> */}

<div className="new-blog-cards">
<div class="blog-card" data-aos="fade-right">
    <div class="meta">
      <div class="photo"></div>
      <ul class="details">
        <li class="author"><a href="#">John Doe</a></li>
        <li class="date">Aug. 24, 2015</li>
      
      </ul>
    </div>
    <div class="description">
      <h1>Learning to Code</h1>
      {/* <h2>Opening a door to the future</h2> */}
      <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad eum dolorum architecto obcaecati enim dicta praesentium, quam nobis! Neque ad aliquam facilis numquam. Veritatis, sit.</p>
      <p class="read-more">
      <a href="#" onClick={()=>setmodal(true)}>Read More</a>
        <Modal
        size="lg"
        isOpen={modal}
        toggle={() => setmodal(!modal)}>
        <ModalHeader toggle={() => setmodal(!modal)}>
         <h1 className="ModalHeader">Latest Blogs</h1>
        </ModalHeader>
        <ModalBody className="ModalBody">
        <img className="blogimg" src={blogimg}/>
        <br/>
        <br/>
          <h1>Blog Title</h1>
          <p>Create a beautiful blog that fits your style. Choose from a selection of easy-to-use templates – all with flexible layouts and hundreds of background images – or design something new.
          Give your blog the perfect home. Get a blogspot.com domain or buy a custom domain with just a few clicks.</p>
          
        </ModalBody>

        </Modal>
      </p>
    </div>
  </div>
  <div class="blog-card alt" data-aos="fade-left">
    <div class="meta">
      <div class="photo"></div>
      <ul class="details">
        <li class="author"><a href="#">Jane Doe</a></li>
        <li class="date">July. 15, 2015</li>
      
      </ul>
    </div>
    <div class="description">
      <h1>Mastering the Language</h1>
      {/* <h2>Java is not the same as JavaScript</h2> */}
      <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad eum dolorum architecto obcaecati enim dicta praesentium, quam nobis! Neque ad aliquam facilis numquam. Veritatis, sit.</p>

      <p class="read-more">
        <a href="#" onClick={()=>setmodal(true)}>Read More</a>
        <Modal
        size="lg"
        isOpen={modal}
        toggle={() => setmodal(!modal)}>
        <ModalHeader toggle={() => setmodal(!modal)}>
         <h1 className="ModalHeader">Latest Blogs</h1>
        </ModalHeader>
        <ModalBody className="ModalBody">
        <img className="blogimg" src={blogimg}/>
        <br/>
        <br/>
          <h1>Blog Title</h1>
          <p>Create a beautiful blog that fits your style. Choose from a selection of easy-to-use templates – all with flexible layouts and hundreds of background images – or design something new.
          Give your blog the perfect home. Get a blogspot.com domain or buy a custom domain with just a few clicks.</p>
          
        </ModalBody>

        </Modal>
      </p>
    </div>
  </div>
</div>


    <br/>
    <br/>

      <Footer/>
    </>
  );
}

export default Introduction;
