import React from 'react'
import logo from '../Assets/WhatsApp_Image_2023-10-05_at_17.25 1.svg'
import social1 from '../Assets/social (1).svg'
import social2 from '../Assets/social (2).svg'
import social3 from '../Assets/social (3).svg'
import social4 from '../Assets/social (4).svg'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <>
    <div className='footer-top'>
    <div className='f1'>
    <div className='footerlogo'>
       <img src={logo}/>
        <p className='name'> Mindfinia Consulting </p>
       </div>
        <br/>
        <p>

        Making amazing things happen with data, analytics & AI.
        </p>
        <br/>
    </div>
    <div className='f2'>
    <ul>Links
            <li ><Link className='wht' to='/WhoWeAre'> Who we are?</Link></li>
            <li><Link className='wht' to='/WhatWeDo'>What we do?</Link></li>
            <li><Link className='wht' to='/Solution'>Solution</Link></li>
            <li><Link className='wht' to='/ContactUs'>Contact Us</Link></li>

        </ul>
   


    </div>

<div className='f4'>
        <h3>Contact us</h3>
        <br/>
            <p className='gp'><i class="bi bi-envelope-fill"></i>sales@mindfinia.com </p>
        <br/>

            <p className='gp'><i class="bi bi-telephone-fill"></i>+91-8700773262</p>
            <br/>
<p className='gp'><i class="bi bi-geo-alt-fill"></i>A-193, SFTODAY HOMES,<br/> BLOOSAM 1, SECTOR 47,<br/> GURGAON-122018, HARYANA</p>
{/* <p className='gp'><i class="bi bi-geo-alt-fill"></i>XYZ, Main road, Delhi,India,452006</p> */}
        </div>

   
    <div className='f3'>
    <h3>Follow Us</h3>
    <br/>
         <div className='social'>
         <img src={social1}/>
            <img src={social2}/>
            <img src={social3}/>
            <img src={social4}/>
         </div>

        </div>
    

    </div>




     
        <div className='foot-bottom'>
        <p>© 2023 Copyright by Stirx Digital. All rights reserved.</p>

        </div>
    </>
  )
}

export default Footer