import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Introduction from "./Pages/Introduction";
import Products from "./Pages/Products";
import Career from "./Pages/Career";
import Blog from "./Pages/Blog";
import BlogDetail from "./Pages/BlogDetail";
import Contact from "./Pages/Contact";
import Mission from "./Pages/Mission";
import Vission from "./Pages/Vission";
import CaseStudies from "./Pages/CaseStudies";
import Services from "./Pages/Services";
import CaseStudies2 from "./Pages/CaseStudies2";
import CaseStudies3 from "./Pages/CaseStudies3";
import CaseStudies4 from "./Pages/CaseStudies4";
import "bootstrap/dist/css/bootstrap.css";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/WhoWeAre" element={<About />} />
          <Route path="/WhatWeDo" element={<Introduction />} />
          <Route path="/Solution" element={<Products />} />
          <Route path="/career" element={<Career />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/introduction/blogdetails" element={<BlogDetail />} />
          <Route path="/ContactUs" element={<Contact />} />
          <Route path="/mission" element={<Mission />} />
          <Route path="/vission" element={<Vission />} />
          <Route path="/casestudies" element={<CaseStudies />} />
          <Route path="/casestudies2" element={<CaseStudies2 />} />
          <Route path="/casestudies3" element={<CaseStudies3 />} />
          <Route path="/casestudies4" element={<CaseStudies4 />} />
          <Route path="/services" element={<Services />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
