import React from 'react'
import Navbar from '../Components/Navbar'
import blogimg from '../Assets/blg.jpg'
import Footer from '../Components/Footer'
import { Link } from 'react-router-dom';


function Blog() {
  return (
    <>
        <Navbar/>
        <div className='center'>
        <br/>
        <br/>
        <div className='line'>

        </div>
<h1>MINDFINIA</h1>
<br/>

<h3>Latest Blogs</h3>
        </div>
        <br/>
        <br/>
        <div className='blog'>
  <div className='blog-card-container'>
   <div className='blog-card'>
   <Link to='./blogdetails' className='link'>
      <img src={blogimg} alt='Blog Image'/>
      <h2>Title Name</h2>
      <p>Description</p>
      <button>Read More</button>
   </Link>
    </div>
    <div className='blog-card'>
    <Link to='./blogdetails' className='link'>
      <img src={blogimg} alt='Blog Image'/>
      <h2>Title Name</h2>
      <p>Description</p>
      <button>Read More</button>
   </Link>
    </div>
    <div className='blog-card'>
    <Link to='./blogdetails' className='link'>
      <img src={blogimg} alt='Blog Image'/>
      <h2>Title Name</h2>
      <p>Description</p>
      <button>Read More</button>
   </Link>
    </div>
    <div className='blog-card'>
    <Link to='./blogdetails' className='link'>
      <img src={blogimg} alt='Blog Image'/>
      <h2>Title Name</h2>
      <p>Description</p>
      <button>Read More</button>
   </Link>
    </div>
    <div className='blog-card'>
    <Link to='./blogdetails' className='link'>
      <img src={blogimg} alt='Blog Image'/>
      <h2>Title Name</h2>
      <p>Description</p>
      <button>Read More</button>
   </Link>
    </div>
    <div className='blog-card'>
      <img src={blogimg} alt='Blog Image'/>
      <h2>Title Name</h2>
      <p>Description</p>
      <button>Read More</button>
    </div>
    <div className='blog-card'>
      <img src={blogimg} alt='Blog Image'/>
      <h2>Title Name</h2>
      <p>Description</p>
      <button>Read More</button>
    </div>
    <div className='blog-card'>
      <img src={blogimg} alt='Blog Image'/>
      <h2>Title Name</h2>
      <p>Description</p>
      <button>Read More</button>
    </div>
  </div>
</div>
<Footer/>
    </>
  )
}

export default Blog