import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'

function Mission() {
  return (
   <>
   <Navbar/>
  
        <Footer/>
   </>
  )
}

export default Mission