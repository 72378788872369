import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { animateScroll as scroll, scroller } from 'react-scroll';
import AOS from "aos";
import Navbar from "../Components/Navbar";
import img from '../Assets/image 1010.svg'
import { useState } from 'react';
import img1 from "../Assets/image 1015.svg";
import img2 from '../Assets/image 1009.svg'
import img3 from '../Assets/image 1010.svg'
import img4 from '../Assets/image 1011.svg'
import img5 from '../Assets/image 1012.svg'
import Footer from "../Components/Footer";
import sys1 from '../Assets/syn logo (1).svg'
import { Link } from "react-router-dom";
import ser1 from '../Assets/64641.jpg'
import ser2 from '../Assets/2109.i402.024.F.m004.c9.Architect flat composition.jpg'
import ser3 from '../Assets/3937443.jpg'

import ser4 from '../Assets/19197279.jpg'
import ser5 from '../Assets/19467.jpg'
import ser6 from '../Assets/Wavy_Tech-12_Single-03.jpg'
import ser7 from '../Assets/Wavy_Tech-22_Single-08.jpg'
import serc1 from '../Assets/18134.jpg'
import serc2 from '../Assets/5921623_20549.jpg'
import serc3 from '../Assets/10782741_19197279.jpg'
import serc4 from '../Assets/10782741_19197279.jpg'

import serc5 from '../Assets/11059252_46948.jpg'
import serc6 from '../Assets/metadata-management-diagram.svg'
import serc7 from '../Assets/990709_OHPGPB0.jpg'

import serclodu1 from '../Assets/795312_12026-NPZBK9.jpg'
import serclodu2 from '../Assets/12470237_Wavy_Tech-02_Single-10.jpg'
import serclodu3 from '../Assets/23128858_1902.i039.011.P.m004.c30.cloud services isometric icons-08.jpg'
import serclodu5 from '../Assets/xenonstack-cloud-native-wheel.svg'
import serclodu4 from '../Assets/image_master_data_management.png'


import productimg from '../Assets/konux (1).gif'
import yourGif from '../Assets/image_processing20200605-5818-1h8nmrn.gif';

function Products() {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const getAccordionIcon = (index) => (activeIndex === index ? '-' : '+');

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sectionId = params.get('section');

    if (sectionId) {
      // Scroll to the specified section
      scroller.scrollTo(sectionId, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    } else {
      // Scroll to the top if no section is specified
      scroll.scrollToTop();
    }
  }, [location.search]);
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <>
      <Navbar />
     <div className="banner3">
     <h2 data-aos="fade-right">SEO Increase<br/> and optimization</h2>
     <br/>
      <p data-aos="fade-right">
      Achieve SEO excellence by embracing mobile-friendly practices.<br/>
      Optimize content, master keywords, and climb <br/>search rankings.
      </p>

     </div>
     <br/>
     <br/>
      {/* <div className="about-section about-section2">
        <h1>Products</h1>
        <br />
        <p>
          MindFinia is not just a name; it's a promise and a philosophy. We are
          a dynamic and innovative company
          <br />
          specializing in the realms of data, analytics, and artificial
          intelligence. Our name, a fusion of 'Mind' and 'Infinity,'
          <br />
          reflects our core belief in the limitless potential of human intellect
          and the endless possibilities of data.
        </p>

        <br />
        <br />

        <img id='abt-banner' className="newstatic newstatic2" src={img1} />
      </div> */}
      {/* <div className="our-servies-section">
      <div className="our-servies-section-left">
      <h2>SEO Increase and optimization</h2>
      <br/><br/>
      <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br/>
       Dictumst habitant viverra placerat volutpat adipiscing urna <br/>
       risus, a turpis. Orci, aliquam in id etiam praesent s
      </p>
      <br/><br/>
      <br/><br/>

<img className="prd-img"src={img2}/>

<br/><br/>
<br/><br/>




<h2 >SEO Increase and optimization</h2>
      <br/><br/>
      <p >
      Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br/>
       Dictumst habitant viverra placerat volutpat adipiscing urna <br/>
       risus, a turpis. Orci, aliquam in id etiam praesent s
      </p>
      <br/><br/>
      <br/><br/>

<img className="prd-img" src={img4} />
</div>
 <div className="our-servies-section-right">
<img className="prd-img" src={img3}/>
<br/><br/>

<h2>SEO Increase and optimization</h2>
      <br/><br/>
      <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br/>
       Dictumst habitant viverra placerat volutpat adipiscing urna <br/>
       risus, a turpis. Orci, aliquam in id etiam praesent s
      </p>



      <br/><br/>
<br/><br/>



      <img  className="prd-img" src={img5}/>
<br/><br/>
<h2>SEO Increase and optimization</h2>
      <br/><br/>
      <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br/>
       Dictumst habitant viverra placerat volutpat adipiscing urna <br/>
       risus, a turpis. Orci, aliquam in id etiam praesent s
      </p>


</div>

      </div> */}
      {/* <div className="serviesSection" id="Products">
      <div className="servies">
      <img src={img5}/>
      <div className="serviesSection-text">
      <h2>Fluid Dashboard</h2>
      <br/>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
Dictumst habitant viverra placerat volutpat adipiscing urna
risus, a turpis. Orci, aliquam in id etiam praesent Lorem ipsum dolor sit amet, consectetur adipiscing elit.
Dictumst habitant viverra placerat volutpat adipiscing urna
risus, a turpis. Orci, aliquam in id etiam praesent sLorem ipsum dolor sit amet, consectetur adipiscing elit.
Dictumst habitant viverra placerat volutpat adipiscing urna
risus, a turpis. Orci, aliquam in id etiam praesent sLorem ipsum dolor sit amet, consectetur adipiscing elit.
Dictumst habitant viverra placerat volutpat adipiscing urna
risus, a turpis. Orci, aliquam in id etiam praesent sLorem ipsum dolor sit amet, consectetur adipiscing elit.
Dictumst habitant viverra placerat volutpat adipiscing urna
risus, a turpis. Orci, aliquam in id etiam praesent s</p>
<br/>
<button>Read More</button>

      </div>

      </div>
      <br/>
      <br/>
      <div className="servies">
      <img src={img2}/>
      <div className="serviesSection-text">
      <h2>SEO Increase and optimization</h2>
      <br/>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
Dictumst habitant viverra placerat volutpat adipiscing urna
risus, a turpis. Orci, aliquam in id etiam praesent Lorem ipsum dolor sit amet, consectetur adipiscing elit.
Dictumst habitant viverra placerat volutpat adipiscing urna
risus, a turpis. Orci, aliquam in id etiam praesent sLorem ipsum dolor sit amet, consectetur adipiscing elit.
Dictumst habitant viverra placerat volutpat adipiscing urna
risus, a turpis. Orci, aliquam in id etiam praesent sLorem ipsum dolor sit amet, consectetur adipiscing elit.
Dictumst habitant viverra placerat volutpat adipiscing urna
risus, a turpis. Orci, aliquam in id etiam praesent sLorem ipsum dolor sit amet, consectetur adipiscing elit.
Dictumst habitant viverra placerat volutpat adipiscing urna
risus, a turpis. Orci, aliquam in id etiam praesent s</p>
<br/>
<button>Read More</button>

      </div>

      </div>
      <br/>
      <br/>
      <div className="servies">
      <img src={img3}/>
      <div className="serviesSection-text">
      <h2>SEO Increase and optimization</h2>
      <br/>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
Dictumst habitant viverra placerat volutpat adipiscing urna
risus, a turpis. Orci, aliquam in id etiam praesent Lorem ipsum dolor sit amet, consectetur adipiscing elit.
Dictumst habitant viverra placerat volutpat adipiscing urna
risus, a turpis. Orci, aliquam in id etiam praesent sLorem ipsum dolor sit amet, consectetur adipiscing elit.
Dictumst habitant viverra placerat volutpat adipiscing urna
risus, a turpis. Orci, aliquam in id etiam praesent sLorem ipsum dolor sit amet, consectetur adipiscing elit.
Dictumst habitant viverra placerat volutpat adipiscing urna
risus, a turpis. Orci, aliquam in id etiam praesent sLorem ipsum dolor sit amet, consectetur adipiscing elit.
Dictumst habitant viverra placerat volutpat adipiscing urna
risus, a turpis. Orci, aliquam in id etiam praesent s</p>
<br/>
<button>Read More</button>

      </div>

      </div>
      <br/>
      <br/>
      <div className="servies">
      <img src={img4}/>
      <div className="serviesSection-text">
      <h2>SEO Increase and optimization</h2>
      <br/>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
Dictumst habitant viverra placerat volutpat adipiscing urna
risus, a turpis. Orci, aliquam in id etiam praesent Lorem ipsum dolor sit amet, consectetur adipiscing elit.
Dictumst habitant viverra placerat volutpat adipiscing urna
risus, a turpis. Orci, aliquam in id etiam praesent sLorem ipsum dolor sit amet, consectetur adipiscing elit.
Dictumst habitant viverra placerat volutpat adipiscing urna
risus, a turpis. Orci, aliquam in id etiam praesent sLorem ipsum dolor sit amet, consectetur adipiscing elit.
Dictumst habitant viverra placerat volutpat adipiscing urna
risus, a turpis. Orci, aliquam in id etiam praesent sLorem ipsum dolor sit amet, consectetur adipiscing elit.
Dictumst habitant viverra placerat volutpat adipiscing urna
risus, a turpis. Orci, aliquam in id etiam praesent s</p>
<br/>
<button>Read More</button>

      </div>

      </div>
      <br/>
      <br/>
     

      </div> */}
      <div className="center" id="Products">

      <div className="line" > </div>
  
  <h1 className="rproject">Our Products</h1>

      </div>
      <br/>
      <br/>
      <div className="product-cards" >
      <div className="product-card" data-aos="zoom-out-up">
      <div className="product-img">
        <img src={productimg}/>
      </div>
      <div className="product-text">
      <h1>Fluid Dashboard</h1>

<button>Read More</button>
      </div>


      </div>
      <div className="product-card" data-aos="zoom-out-up">
      <div className="product-img">
        <img src={yourGif}/>
      </div>
      <div className="product-text">
      <h1>Monitoring Dashboard</h1>

<button>Read More</button>
      </div>


      </div>

      </div>


      <br/><br/>
      {/* <div className="offer" id="Services">
      <br/>
      <br/>
      <div className="line" > </div>
  
      <h1>Services we offer</h1>
      <br/>
      <br/>

      <div className="ser-cards">
   <div className="ser-cards-row">
   <div className="ser-card">
<div className="sys">
<img src={sys1}/>

</div>
<br/>

<h1>Mobile App Development</h1>
<br/>

<p>A Website is an extension of yourself 
and we can help you to express it properly.
 Your website is your number one marketing
  asset because we live in a digital age.</p>
</div>
    <div className="ser-card">
<div className="sys">
<img src={sys1}/>

</div>
<br/>

<h1>Mobile App Development</h1>
<br/>

<p>A Website is an extension of yourself 
and we can help you to express it properly.
 Your website is your number one marketing
  asset because we live in a digital age.</p>
</div>
   </div>
   <div className="ser-cards-row">
   <div className="ser-card">
<div className="sys">
<img src={sys1}/>

</div>
<br/>

<h1>Mobile App Development</h1>
<br/>

<p>A Website is an extension of yourself 
and we can help you to express it properly.
 Your website is your number one marketing
  asset because we live in a digital age.</p>
</div>
    <div className="ser-card">
<div className="sys">
<img src={sys1}/>

</div>
<br/>

<h1>Mobile App Development</h1>
<br/>

<p>A Website is an extension of yourself 
and we can help you to express it properly.
 Your website is your number one marketing
  asset because we live in a digital age.</p>
</div>
   </div>

      </div>

      </div>
<br/><br/>
<br/> */}<br/>
<div className="center" id="Services">
<div className="line" data-aos="flip-left" data-aos-duration="4000"> </div>
  
  <h1 className="weoffer" >Services we offer</h1>
</div>
<br/>
<div className="servies-acc">

{/* <div className="servies-acc-heading">
          <h2>Strategy & Consulting</h2>
<div className="line" > </div>

          <div className="conf">
            <div className="conf-text">
              <h1>
              Accelerating Digital Transformation using AI
              </h1>
              <p>We provide strategic guidance on leveraging Artificial Intelligence (AI) to propel your digital transformation journey. Our experts collaborate with your team to identify AI opportunities that align with your business objectives, ensuring a seamless integration of advanced technologies.</p>
             
              
            </div>
            <div className="conf-img">
              <img src={ser1} />
            </div>
          </div>
          <br />
          <hr></hr>
          <div className="conf">
          <div className="conf-img">
              <img src={ser2} />
            </div>
            <div className="conf-text">
              <h1>
              Standards & Architecture
              </h1>
              <p>Our team specializes in establishing robust standards and architectures for your data solutions. We design and implement scalable and secure solutions, adhering to industry best practices. This ensures a solid foundation for your systems and applications, promoting efficiency and future scalability.</p>
             
              
            </div>
         
          </div>
          <br />
          <hr></hr>

          <div className="conf">
            <div className="conf-text">
              <h1>
              Data Modernization

              </h1>
              <p>Stay ahead in the data-driven landscape with our data modernization services. We assist in transforming legacy data systems into agile, cloud-ready structures. This empowers your organization to harness the full potential of data, facilitating faster decision-making and enhanced business agility.</p>
             
              
            </div>
            <div className="conf-img">
              <img src={ser3} />
            </div>
          </div>
          <br />
          <hr></hr>

          <div className="conf">
          <div className="conf-img">
              <img src={ser4} />
            </div>
            <div className="conf-text">
              <h1>
              Data Quality Management
              </h1>
              <p>Ensuring the accuracy and reliability of your data is paramount. Our data quality management services focus on implementing processes and tools to cleanse, standardize, and validate data. This results in improved data quality, boosting the effectiveness of your analytics and decision-making processes.</p>
             
              
            </div>
          
          </div>
          <br />
          <hr></hr>
          <div className="conf">
            <div className="conf-text">
              <h1>
              Master Data Management


              </h1>
              <p>Centralize and harmonize your critical business data with our Master Data Management solutions. We help you create a single, authoritative source of truth for essential information, enabling better data governance, reducing errors, and enhancing the overall efficiency of your business operations.</p>
             
              
            </div>
            <div className="conf-img">
              <img src={ser7} />
            </div>
          </div>
          <br />
          <hr></hr>
          <div className="conf">
          <div className="conf-img">
              <img src={ser5} />
            </div>
            <div className="conf-text">
              <h1>
              Analytics & Decision Intelligence
              </h1>
              <p>Unlock the potential of your data through our analytics and decision intelligence services. We employ advanced analytics tools and techniques to derive meaningful insights, empowering your organization to make informed decisions. From descriptive to predictive analytics, we cover the entire spectrum to drive business success.</p>
            </div>
           
          </div>
          <br />
          <hr></hr>
          <div className="conf">
            <div className="conf-text">
              <h1>
              Intelligent Automation
              </h1>
              <p>Embrace efficiency and innovation with our intelligent automation solutions. We leverage cutting-edge technologies such as robotic process automation (RPA) and AI-driven automation to streamline repetitive tasks, reduce operational costs, and enhance overall business productivity.</p>
            </div>
            <div className="conf-img">
              <img src={ser6} />
            </div>
          </div>
</div>

<div className="servies-acc-heading">
          <h2>Data & Analytics</h2>
<div className="line" > </div>

          <div className="conf">
            <div className="conf-text">
              <h1>
              Data & Product Engineering
              </h1>
              <p>Transform your raw data into valuable insights and products with our Data & Product Engineering services. Our team of skilled engineers and data scientists work collaboratively to design and build robust data pipelines, ensuring the efficient flow and processing of data. We specialize in turning data into actionable intelligence and innovative products that drive business growth.</p>
             
              
            </div>
            <div className="conf-img">
              <img src={serc1} />
            </div>
          </div>
          <br />
          <hr></hr>
          <div className="conf">
          <div className="conf-img">
              <img src={serc2} />
            </div>
            <div className="conf-text">
              <h1>
              Data Lake & Warehousing
              </h1>
              <p>Empower your organization with scalable and efficient data storage solutions. Our Data Lake & Warehousing services focus on designing and implementing data repositories that accommodate diverse data types. Whether it's structured or unstructured data, we create centralized repositories that support analytics, reporting, and business intelligence.</p>
             
              
            </div>
         
          </div>
          <br />
          <hr></hr>

          <div className="conf">
            <div className="conf-text">
              <h1>
              Data Quality Management

              </h1>
              <p>Ensure the reliability and accuracy of your data assets through our Data Quality Management services. We implement comprehensive strategies to monitor, cleanse, and enhance the quality of your data. This not only improves the integrity of your information but also boosts the effectiveness of analytics and decision-making processes.</p>
             
              
            </div>
            <div className="conf-img">
              <img src={serc3} />
            </div>
          </div>
          <br />
          <hr></hr>

          <div className="conf">
          <div className="conf-img">
              <img src={serc5} />
            </div>
            <div className="conf-text">
              <h1>
              Master Data Management
              </h1>
              <p>Achieve a single, authoritative source of truth for critical business data with our Master Data Management services. We help you establish and maintain consistent, accurate master data across your organization. This ensures data integrity, reduces redundancies, and enhances overall business efficiency.</p>
             
              
            </div>
          
          </div>
          <br />
          <hr></hr>

          <div className="conf">
         
            <div className="conf-text">
              <h1>
              Metadata Management
              </h1>
              <p>Uncover the full value of your data by effectively managing metadata. Our Metadata Management services focus on organizing, documenting, and governing metadata to provide context and understanding of your data assets. This facilitates easier data discovery, lineage tracking, and compliance with data governance standards.</p>
             
              
            </div>
            <div className="conf-img">
              <img src={serc6} />
            </div>
          
          </div>
          <br />
          <hr></hr>

          <div className="conf">
          <div className="conf-img">
              <img src={serc7} />
            </div>
            <div className="conf-text">
              <h1>
              Decision Intelligence through accelerated dashboards
              </h1>
              <p>Empower your decision-makers with real-time insights through our accelerated dashboard solutions. We specialize in designing and implementing dashboards that provide a visually intuitive representation of key performance indicators (KPIs) and critical business metrics. Accelerate your decision-making processes with actionable intelligence at your fingertips.</p>
             
              
            </div>
          
          </div>


          </div>
          <br />
          <br />

          <div className="servies-acc-heading">
          <h2>Cloud</h2>
          <div className="line"></div>
          <div className="conf">
            <div className="conf-text">
              <h1>
              Cloud Strategy & Advisory
              </h1>
              <p>Navigate the complexities of the cloud landscape with our Cloud Strategy & Advisory services. Our experts collaborate closely with your team to formulate a customized cloud strategy aligned with your business goals. From assessing your current IT landscape to identifying optimal cloud solutions, we provide comprehensive guidance to ensure a seamless and successful cloud adoption journey.</p>
             
              
            </div>
            <div className="conf-img">
              <img src={serclodu1} />
            </div>
          </div>
          <br />
          <hr></hr>
          <div className="conf">
          <div className="conf-img">
              <img src={serclodu2} />
            </div>
            <div className="conf-text">
              <h1>
              Migration & Modernization
              </h1>
              <p>Streamline your transition to the cloud with our Migration & Modernization services. We specialize in migrating your applications, data, and infrastructure to cloud environments efficiently and securely. Whether it's a lift-and-shift approach or a more comprehensive modernization strategy, we ensure minimal downtime and maximum benefits from your cloud.</p>
             
              
            </div>
         
          </div>
          <br />
          <hr></hr>

          <div className="conf">
            <div className="conf-text">
              <h1>
              Cloud Infrastructure
              </h1>
              <p>Build a scalable, secure, and high-performance cloud infrastructure tailored to your specific needs. Our Cloud Infrastructure services cover the design, implementation, and optimization of cloud environments. From selecting the right cloud providers to configuring network architecture, we ensure that your infrastructure supports the demands of your applications and services.</p>
             
              
            </div>
            <div className="conf-img">
              <img src={serclodu3} />
            </div>
          </div>
          <br />
          <hr></hr>

          <div className="conf">
          <div className="conf-img">
              <img src={serclodu4} />
            </div>
            <div className="conf-text">
              <h1>
              Master Data Management
              </h1>
              <p>Achieve a single, authoritative source of truth for critical business data with our Master Data Management services. We help you establish and maintain consistent, accurate master data across your organization. This ensures data integrity, reduces redundancies, and enhances overall business efficiency.</p>
             
              
            </div>
          
          </div>
          <br />
          <hr></hr>

<div className="conf">

  <div className="conf-text">
    <h1>
    Cloud Native Development & Tool Recommendation
    </h1>
    <p>Embrace agility and innovation with our Cloud Native Development services. We guide your development teams in building applications that are designed for the cloud from the ground up. Our experts recommend and implement the right set of cloud-native tools, frameworks, and methodologies to enhance development efficiency, scalability, and resilience in cloud environments.</p>
   
    
  </div>
  <div className="conf-img">
    <img src={serclodu5} />
  </div>

</div>

          </div>           */}
     <details>
          <summary className="servies-heading">Strategy & Consulting</summary>
          <div className="line" data-aos="flip-left" data-aos-duration="4000"></div>

          <br/>

          <div>

<div id="plus">
<button
      
        className={`accordion ${activeIndex === 0 ? 'active' : ''}`}
        onClick={() => handleAccordionClick(0)}
      >
       Accelerating Digital Transformation using AI
      </button>
      <span className="pluseicon">{getAccordionIcon(0)}</span>
</div>
      <div className="panel" style={{ display: activeIndex === 0 ? 'block' : 'none' }}>
      <div className="conf">
            <div className="conf-text">
          
              <p>We provide strategic guidance on leveraging Artificial Intelligence (AI) to propel your digital transformation journey. Our experts collaborate with your team to identify AI opportunities that align with your business objectives, ensuring a seamless integration of advanced technologies.</p>
             
              
            </div>
            <div className="conf-img">
              <img src={ser1} />
            </div>
          </div>
      </div>

      <div id="plus">
<button
      
        className={`accordion ${activeIndex === 1 ? 'active' : ''}`}
        onClick={() => handleAccordionClick(1)}
      >
       Standards & Architecture
      </button>
      <span className="pluseicon">{getAccordionIcon(1)}</span>
</div>
      <div className="panel" style={{ display: activeIndex === 1 ? 'block' : 'none' }}>
      <div className="conf">
          <div className="conf-text">
             
              <p>Our team specializes in establishing robust standards and architectures for your data solutions. We design and implement scalable and secure solutions, adhering to industry best practices. This ensures a solid foundation for your systems and applications, promoting efficiency and future scalability.</p>
             
              
            </div>
          <div className="conf-img">
              <img src={ser2} />
            </div>
         
         
          </div>
      </div>

     <div id="plus">
<button
      
        className={`accordion ${activeIndex === 2 ? 'active' : ''}`}
        onClick={() => handleAccordionClick(2)}
      >
        Data Modernization
      </button>
      <span className="pluseicon">{getAccordionIcon(2)}</span>
</div>
      <div className="panel" style={{ display: activeIndex === 2 ? 'block' : 'none' }}>
      <div className="conf">
            <div className="conf-text">
            
              <p>Stay ahead in the data-driven landscape with our data modernization services. We assist in transforming legacy data systems into agile, cloud-ready structures. This empowers your organization to harness the full potential of data, facilitating faster decision-making and enhanced business agility.</p>
             
              
            </div>
            <div className="conf-img">
              <img src={ser3} />
            </div>
          </div>
      </div>

      <div id="plus">
<button
      
        className={`accordion ${activeIndex === 3 ? 'active' : ''}`}
        onClick={() => handleAccordionClick(3)}
      >
       Data Quality Management
      </button>
      <span className="pluseicon">{getAccordionIcon(3)}</span>
</div>
      <div className="panel" style={{ display: activeIndex === 3 ? 'block' : 'none' }}>
      <div className="conf">
       
       <div className="conf-text">
        
         <p>Ensuring the accuracy and reliability of your data is paramount. Our data quality management services focus on implementing processes and tools to cleanse, standardize, and validate data. This results in improved data quality, boosting the effectiveness of your analytics and decision-making processes.</p>
        
         
       </div>
       <div className="conf-img">
         <img src={ser4} />
       </div>
     
     </div>
      </div>



      <div id="plus">
<button
      
        className={`accordion ${activeIndex === 4 ? 'active' : ''}`}
        onClick={() => handleAccordionClick(4)}
      >
        Master Data Management
      </button>
      <span className="pluseicon">{getAccordionIcon(4)}</span>
</div>
      <div className="panel" style={{ display: activeIndex === 4 ? 'block' : 'none' }}>
      <div className="conf">
            <div className="conf-text">
            
              <p>Centralize and harmonize your critical business data with our Master Data Management solutions. We help you create a single, authoritative source of truth for essential information, enabling better data governance, reducing errors, and enhancing the overall efficiency of your business operations.</p>
             
              
            </div>
            <div className="conf-img">
              <img src={ser7} />
            </div>
          </div>
      </div>



      <div id="plus">
<button
      
        className={`accordion ${activeIndex === 5 ? 'active' : ''}`}
        onClick={() => handleAccordionClick(5)}
      >
       Analytics & Decision Intelligence
      </button>
      <span className="pluseicon">{getAccordionIcon(5)}</span>
</div>
      <div className="panel" style={{ display: activeIndex === 5 ? 'block' : 'none' }}>
      <div className="conf">
         
         <div className="conf-text">
           
           <p>Unlock the potential of your data through our analytics and decision intelligence services. We employ advanced analytics tools and techniques to derive meaningful insights, empowering your organization to make informed decisions. From descriptive to predictive analytics, we cover the entire spectrum to drive business success.</p>
         </div>
         <div className="conf-img">
           <img src={ser5} />
         </div>
       </div>
      </div>



      <div id="plus">
<button
      
        className={`accordion ${activeIndex === 6 ? 'active' : ''}`}
        onClick={() => handleAccordionClick(6)}
      >
       Intelligent Automation
      </button>
      <span className="pluseicon">{getAccordionIcon(6)}</span>
</div>
      <div className="panel" style={{ display: activeIndex === 6 ? 'block' : 'none' }}>
      <div className="conf">
            <div className="conf-text">
           
              <p>Embrace efficiency and innovation with our intelligent automation solutions. We leverage cutting-edge technologies such as robotic process automation (RPA) and AI-driven automation to streamline repetitive tasks, reduce operational costs, and enhance overall business productivity.</p>
            </div>
            <div className="conf-img">
              <img src={ser6} />
            </div>
          </div>
      </div>





    </div>









          {/* <details className="dtls">
          <summary className="servies-heading servies-heading2"> Accelerating Digital Transformation using AI</summary>
          <div className="conf">
            <div className="conf-text">
          
              <p>We provide strategic guidance on leveraging Artificial Intelligence (AI) to propel your digital transformation journey. Our experts collaborate with your team to identify AI opportunities that align with your business objectives, ensuring a seamless integration of advanced technologies.</p>
             
              
            </div>
            <div className="conf-img">
              <img src={ser1} />
            </div>
          </div>
        </details>
        <details className="dtls">
          <summary className="servies-heading servies-heading2">              Standards & Architecture
</summary>
          <div className="conf">
          <div className="conf-text">
             
              <p>Our team specializes in establishing robust standards and architectures for your data solutions. We design and implement scalable and secure solutions, adhering to industry best practices. This ensures a solid foundation for your systems and applications, promoting efficiency and future scalability.</p>
             
              
            </div>
          <div className="conf-img">
              <img src={ser2} />
            </div>
         
         
          </div>
        </details>
        <details className="dtls">
          <summary className="servies-heading servies-heading2"> Data Modernization</summary>
          <div className="conf">
            <div className="conf-text">
            
              <p>Stay ahead in the data-driven landscape with our data modernization services. We assist in transforming legacy data systems into agile, cloud-ready structures. This empowers your organization to harness the full potential of data, facilitating faster decision-making and enhanced business agility.</p>
             
              
            </div>
            <div className="conf-img">
              <img src={ser3} />
            </div>
          </div>
        </details>
        <details className="dtls">
          <summary className="servies-heading servies-heading2">Data Quality Management</summary>
          <div className="conf">
       
            <div className="conf-text">
             
              <p>Ensuring the accuracy and reliability of your data is paramount. Our data quality management services focus on implementing processes and tools to cleanse, standardize, and validate data. This results in improved data quality, boosting the effectiveness of your analytics and decision-making processes.</p>
             
              
            </div>
            <div className="conf-img">
              <img src={ser4} />
            </div>
          
          </div>
        </details>
        <details className="dtls">
          <summary className="servies-heading servies-heading2"> Master Data Management
</summary>
          <div className="conf">
            <div className="conf-text">
            
              <p>Centralize and harmonize your critical business data with our Master Data Management solutions. We help you create a single, authoritative source of truth for essential information, enabling better data governance, reducing errors, and enhancing the overall efficiency of your business operations.</p>
             
              
            </div>
            <div className="conf-img">
              <img src={ser7} />
            </div>
          </div>
        </details>
        <details className="dtls">
          <summary className="servies-heading servies-heading2"> Analytics & Decision Intelligence</summary>
          <div className="conf">
         
            <div className="conf-text">
              
              <p>Unlock the potential of your data through our analytics and decision intelligence services. We employ advanced analytics tools and techniques to derive meaningful insights, empowering your organization to make informed decisions. From descriptive to predictive analytics, we cover the entire spectrum to drive business success.</p>
            </div>
            <div className="conf-img">
              <img src={ser5} />
            </div>
          </div>
        </details>
        <details className="dtls">
          <summary className="servies-heading servies-heading2"> Intelligent Automation</summary>
          <div className="conf">
            <div className="conf-text">
           
              <p>Embrace efficiency and innovation with our intelligent automation solutions. We leverage cutting-edge technologies such as robotic process automation (RPA) and AI-driven automation to streamline repetitive tasks, reduce operational costs, and enhance overall business productivity.</p>
            </div>
            <div className="conf-img">
              <img src={ser6} />
            </div>
          </div>
        </details> */}

        
       
        </details>

        <details>
          <summary className="servies-heading">Data & Analytics</summary>
          <div className="line" data-aos="flip-left" data-aos-duration="4000"></div>



          <br/>

<div>

<div id="plus">
<button

className={`accordion ${activeIndex === 7 ? 'active' : ''}`}
onClick={() => handleAccordionClick(7)}
>
Data & Product Engineering
</button>
<span className="pluseicon">{getAccordionIcon(7)}</span>
</div>
<div className="panel" style={{ display: activeIndex === 7 ? 'block' : 'none' }}>
<div className="conf">
            <div className="conf-text">
            
              <p>Transform your raw data into valuable insights and products with our Data & Product Engineering services. Our team of skilled engineers and data scientists work collaboratively to design and build robust data pipelines, ensuring the efficient flow and processing of data. We specialize in turning data into actionable intelligence and innovative products that drive business growth.</p>
             
              
            </div>
            <div className="conf-img">
              <img src={serc1} />
            </div>
          </div>
</div>

<div id="plus">
<button

className={`accordion ${activeIndex === 8 ? 'active' : ''}`}
onClick={() => handleAccordionClick(8)}
>
Data Lake & Warehousing
</button>
<span className="pluseicon">{getAccordionIcon(8)}</span>
</div>
<div className="panel" style={{ display: activeIndex === 8 ? 'block' : 'none' }}>
<div className="conf">
         <div className="conf-text">
              
              <p>Empower your organization with scalable and efficient data storage solutions. Our Data Lake & Warehousing services focus on designing and implementing data repositories that accommodate diverse data types. Whether it's structured or unstructured data, we create centralized repositories that support analytics, reporting, and business intelligence.</p>
             
              
            </div>
          <div className="conf-img">
              <img src={serc2} />
            </div>
            
         
          </div>
</div>

<div id="plus">
<button

className={`accordion ${activeIndex === 9 ? 'active' : ''}`}
onClick={() => handleAccordionClick(9)}
>
Data Quality Management
</button>
<span className="pluseicon">{getAccordionIcon(9)}</span>
</div>
<div className="panel" style={{ display: activeIndex === 9 ? 'block' : 'none' }}>
<div className="conf">
            <div className="conf-text">
              <p>Ensure the reliability and accuracy of your data assets through our Data Quality Management services. We implement comprehensive strategies to monitor, cleanse, and enhance the quality of your data. This not only improves the integrity of your information but also boosts the effectiveness of analytics and decision-making processes.</p>
             
              
            </div>
            <div className="conf-img">
              <img src={serc3} />
            </div>
          </div>
</div>

<div id="plus">
<button

className={`accordion ${activeIndex === 10 ? 'active' : ''}`}
onClick={() => handleAccordionClick(10)}
>
Master Data Management
</button>
<span className="pluseicon">{getAccordionIcon(10)}</span>
</div>
<div className="panel" style={{ display: activeIndex === 10 ? 'block' : 'none' }}>
<div className="conf">
         
         <div className="conf-text">
        
           <p>Achieve a single, authoritative source of truth for critical business data with our Master Data Management services. We help you establish and maintain consistent, accurate master data across your organization. This ensures data integrity, reduces redundancies, and enhances overall business efficiency.</p>
          
           
         </div>
         <div className="conf-img">
           <img src={serc5} />
         </div>
       
       </div>
</div>



<div id="plus">
<button

className={`accordion ${activeIndex === 11 ? 'active' : ''}`}
onClick={() => handleAccordionClick(11)}
>
Metadata Management
</button>
<span className="pluseicon">{getAccordionIcon(11)}</span>
</div>
<div className="panel" style={{ display: activeIndex === 11 ? 'block' : 'none' }}>
<div className="conf">
         
         <div className="conf-text">
           <h1>
           </h1>
           <p>Uncover the full value of your data by effectively managing metadata. Our Metadata Management services focus on organizing, documenting, and governing metadata to provide context and understanding of your data assets. This facilitates easier data discovery, lineage tracking, and compliance with data governance standards.</p>
          
           
         </div>
         <div className="conf-img">
           <img src={serc6} />
         </div>
       
       </div>
</div>



<div id="plus">
<button

className={`accordion ${activeIndex === 12 ? 'active' : ''}`}
onClick={() => handleAccordionClick(12)}
>
Decision Intelligence through accelerated dashboards
</button>
<span className="pluseicon">{getAccordionIcon(12)}</span>
</div>
<div className="panel" style={{ display: activeIndex === 12 ? 'block' : 'none' }}>
<div className="conf">
          <div className="conf-text">
          
              <p>Empower your decision-makers with real-time insights through our accelerated dashboard solutions. We specialize in designing and implementing dashboards that provide a visually intuitive representation of key performance indicators (KPIs) and critical business metrics. Accelerate your decision-making processes with actionable intelligence at your fingertips.</p>
             
              
            </div>
          <div className="conf-img">
              <img src={serc7} />
            </div>
            
          
          </div>
</div>









</div>














          {/* <details className="dtls">
          <summary className="servies-heading servies-heading2">  Data & Product Engineering</summary>
          <div className="conf">
            <div className="conf-text">
            
              <p>Transform your raw data into valuable insights and products with our Data & Product Engineering services. Our team of skilled engineers and data scientists work collaboratively to design and build robust data pipelines, ensuring the efficient flow and processing of data. We specialize in turning data into actionable intelligence and innovative products that drive business growth.</p>
             
              
            </div>
            <div className="conf-img">
              <img src={serc1} />
            </div>
          </div>
        </details>
        <details className="dtls">
          <summary className="servies-heading servies-heading2">              Data Lake & Warehousing

</summary>
         <div className="conf">
         <div className="conf-text">
              
              <p>Empower your organization with scalable and efficient data storage solutions. Our Data Lake & Warehousing services focus on designing and implementing data repositories that accommodate diverse data types. Whether it's structured or unstructured data, we create centralized repositories that support analytics, reporting, and business intelligence.</p>
             
              
            </div>
          <div className="conf-img">
              <img src={serc2} />
            </div>
            
         
          </div>
        </details>
        <details className="dtls">
          <summary className="servies-heading servies-heading2">Data Quality Management</summary>
          <div className="conf">
            <div className="conf-text">
              <p>Ensure the reliability and accuracy of your data assets through our Data Quality Management services. We implement comprehensive strategies to monitor, cleanse, and enhance the quality of your data. This not only improves the integrity of your information but also boosts the effectiveness of analytics and decision-making processes.</p>
             
              
            </div>
            <div className="conf-img">
              <img src={serc3} />
            </div>
          </div>
        </details>
        <details className="dtls">
          <summary className="servies-heading servies-heading2"> Master Data Management</summary>
          <div className="conf">
         
            <div className="conf-text">
           
              <p>Achieve a single, authoritative source of truth for critical business data with our Master Data Management services. We help you establish and maintain consistent, accurate master data across your organization. This ensures data integrity, reduces redundancies, and enhances overall business efficiency.</p>
             
              
            </div>
            <div className="conf-img">
              <img src={serc5} />
            </div>
          
          </div>
        </details>
        <details className="dtls">
          <summary className="servies-heading servies-heading2">Metadata Management

</summary>
         <div className="conf">
         
         <div className="conf-text">
           <h1>
           </h1>
           <p>Uncover the full value of your data by effectively managing metadata. Our Metadata Management services focus on organizing, documenting, and governing metadata to provide context and understanding of your data assets. This facilitates easier data discovery, lineage tracking, and compliance with data governance standards.</p>
          
           
         </div>
         <div className="conf-img">
           <img src={serc6} />
         </div>
       
       </div>
        </details>
        <details className="dtls">
          <summary className="servies-heading servies-heading2">  Decision Intelligence through accelerated dashboards</summary>
          <div className="conf">
          <div className="conf-text">
          
              <p>Empower your decision-makers with real-time insights through our accelerated dashboard solutions. We specialize in designing and implementing dashboards that provide a visually intuitive representation of key performance indicators (KPIs) and critical business metrics. Accelerate your decision-making processes with actionable intelligence at your fingertips.</p>
             
              
            </div>
          <div className="conf-img">
              <img src={serc7} />
            </div>
            
          
          </div>
        </details> */}
       

        
       
        </details>

        <details>
          <summary className="servies-heading">Cloud</summary>
          <div className="line" data-aos="flip-left" data-aos-duration="4000"></div>

          <br/>

<div>

<div id="plus">
<button

className={`accordion ${activeIndex === 14 ? 'active' : ''}`}
onClick={() => handleAccordionClick(14)}
>
Cloud Strategy & Advisory
</button>
<span className="pluseicon">{getAccordionIcon(14)}</span>
</div>
<div className="panel" style={{ display: activeIndex === 14 ? 'block' : 'none' }}>
<div className="conf">
            <div className="conf-text">
           
              <p>Navigate the complexities of the cloud landscape with our Cloud Strategy & Advisory services. Our experts collaborate closely with your team to formulate a customized cloud strategy aligned with your business goals. From assessing your current IT landscape to identifying optimal cloud solutions, we provide comprehensive guidance to ensure a seamless and successful cloud adoption journey.</p>
             
              
            </div>
            <div className="conf-img">
              <img src={serclodu1} />
            </div>
          </div>
</div>

<div id="plus">
<button

className={`accordion ${activeIndex === 15 ? 'active' : ''}`}
onClick={() => handleAccordionClick(15)}
>
Migration & Modernization
</button>
<span className="pluseicon">{getAccordionIcon(15)}</span>
</div>
<div className="panel" style={{ display: activeIndex === 15 ? 'block' : 'none' }}>
<div className="conf">
           <div className="conf-text">
             
              <p>Streamline your transition to the cloud with our Migration & Modernization services. We specialize in migrating your applications, data, and infrastructure to cloud environments efficiently and securely. Whether it's a lift-and-shift approach or a more comprehensive modernization strategy, we ensure minimal downtime and maximum benefits from your cloud.</p>
             
              
            </div>
          <div className="conf-img">
              <img src={serclodu2} />
            </div>
            
         
          </div>
</div>

<div id="plus">
<button

className={`accordion ${activeIndex === 16 ? 'active' : ''}`}
onClick={() => handleAccordionClick(16)}
>
Cloud Infrastructure
</button>
<span className="pluseicon">{getAccordionIcon(16)}</span>
</div>
<div className="panel" style={{ display: activeIndex === 16 ? 'block' : 'none' }}>
<div className="conf">
            <div className="conf-text">
          
              <p>Build a scalable, secure, and high-performance cloud infrastructure tailored to your specific needs. Our Cloud Infrastructure services cover the design, implementation, and optimization of cloud environments. From selecting the right cloud providers to configuring network architecture, we ensure that your infrastructure supports the demands of your applications and services.</p>
             
              
            </div>
            <div className="conf-img">
              <img src={serclodu3} />
            </div>
          </div>
</div>

<div id="plus">
<button

className={`accordion ${activeIndex === 17 ? 'active' : ''}`}
onClick={() => handleAccordionClick(17)}
>
Master Data Management
</button>
<span className="pluseicon">{getAccordionIcon(17)}</span>
</div>
<div className="panel" style={{ display: activeIndex === 17 ? 'block' : 'none' }}>
<div className="conf">
          
          <div className="conf-text">
            <h1>
            
            </h1>
            <p>Achieve a single, authoritative source of truth for critical business data with our Master Data Management services. We help you establish and maintain consistent, accurate master data across your organization. This ensures data integrity, reduces redundancies, and enhances overall business efficiency.</p>
           
            
          </div>
          <div className="conf-img">
            <img src={serclodu4} />
          </div>
        
        </div>
</div>



<div id="plus">
<button

className={`accordion ${activeIndex === 18 ? 'active' : ''}`}
onClick={() => handleAccordionClick(18)}
>
Cloud Native Development & Tool Recommendation
</button>
<span className="pluseicon">{getAccordionIcon(18)}</span>
</div>
<div className="panel" style={{ display: activeIndex === 18 ? 'block' : 'none' }}>
<div className="conf">

<div className="conf-text">

  <p>Embrace agility and innovation with our Cloud Native Development services. We guide your development teams in building applications that are designed for the cloud from the ground up. Our experts recommend and implement the right set of cloud-native tools, frameworks, and methodologies to enhance development efficiency, scalability, and resilience in cloud environments.</p>
 
  
</div>
<div className="conf-img">
  <img src={serclodu5} />
</div>

</div>
</div>



{/* <div id="plus">
<button

className={`accordion ${activeIndex === 12 ? 'active' : ''}`}
onClick={() => handleAccordionClick(12)}
>
Decision Intelligence through accelerated dashboards
</button>
<span className="pluseicon">{getAccordionIcon(12)}</span>
</div>
<div className="panel" style={{ display: activeIndex === 12 ? 'block' : 'none' }}>
<div className="conf">
          <div className="conf-text">
          
              <p>Empower your decision-makers with real-time insights through our accelerated dashboard solutions. We specialize in designing and implementing dashboards that provide a visually intuitive representation of key performance indicators (KPIs) and critical business metrics. Accelerate your decision-making processes with actionable intelligence at your fingertips.</p>
             
              
            </div>
          <div className="conf-img">
              <img src={serc7} />
            </div>
            
          
          </div>
</div> */}

</div>



























          {/* <details className="dtls">
          <summary className="servies-heading servies-heading2">Cloud Strategy & Advisory</summary>
          <div className="conf">
            <div className="conf-text">
           
              <p>Navigate the complexities of the cloud landscape with our Cloud Strategy & Advisory services. Our experts collaborate closely with your team to formulate a customized cloud strategy aligned with your business goals. From assessing your current IT landscape to identifying optimal cloud solutions, we provide comprehensive guidance to ensure a seamless and successful cloud adoption journey.</p>
             
              
            </div>
            <div className="conf-img">
              <img src={serclodu1} />
            </div>
          </div>
        </details>
        <details className="dtls">
          <summary className="servies-heading servies-heading2"> Migration & Modernization

</summary>
           <div className="conf">
           <div className="conf-text">
             
              <p>Streamline your transition to the cloud with our Migration & Modernization services. We specialize in migrating your applications, data, and infrastructure to cloud environments efficiently and securely. Whether it's a lift-and-shift approach or a more comprehensive modernization strategy, we ensure minimal downtime and maximum benefits from your cloud.</p>
             
              
            </div>
          <div className="conf-img">
              <img src={serclodu2} />
            </div>
            
         
          </div>
        </details>
        <details className="dtls">
          <summary className="servies-heading servies-heading2">Cloud Infrastructure</summary>
          <div className="conf">
            <div className="conf-text">
          
              <p>Build a scalable, secure, and high-performance cloud infrastructure tailored to your specific needs. Our Cloud Infrastructure services cover the design, implementation, and optimization of cloud environments. From selecting the right cloud providers to configuring network architecture, we ensure that your infrastructure supports the demands of your applications and services.</p>
             
              
            </div>
            <div className="conf-img">
              <img src={serclodu3} />
            </div>
          </div>
        </details>
        <details className="dtls">
          <summary className="servies-heading servies-heading2">Master Data Management</summary>
          <div className="conf">
          
            <div className="conf-text">
              <h1>
              
              </h1>
              <p>Achieve a single, authoritative source of truth for critical business data with our Master Data Management services. We help you establish and maintain consistent, accurate master data across your organization. This ensures data integrity, reduces redundancies, and enhances overall business efficiency.</p>
             
              
            </div>
            <div className="conf-img">
              <img src={serclodu4} />
            </div>
          
          </div>
        </details>
        <details className="dtls">
          <summary className="servies-heading servies-heading2">Cloud Native Development & Tool Recommendation

</summary>
       <div className="conf">

<div className="conf-text">

  <p>Embrace agility and innovation with our Cloud Native Development services. We guide your development teams in building applications that are designed for the cloud from the ground up. Our experts recommend and implement the right set of cloud-native tools, frameworks, and methodologies to enhance development efficiency, scalability, and resilience in cloud environments.</p>
 
  
</div>
<div className="conf-img">
  <img src={serclodu5} />
</div>

</div>
        </details>
        <details className="dtls">
          <summary className="servies-heading servies-heading2">  Decision Intelligence through accelerated dashboards</summary>
          <div className="conf">
          <div className="conf-text">
          
              <p>Empower your decision-makers with real-time insights through our accelerated dashboard solutions. We specialize in designing and implementing dashboards that provide a visually intuitive representation of key performance indicators (KPIs) and critical business metrics. Accelerate your decision-making processes with actionable intelligence at your fingertips.</p>
             
              
            </div>
          <div className="conf-img">
              <img src={serc7} />
            </div>
            
          
          </div>
        </details>
        */}

        
       
        </details>


     
        {/* <details>
          <summary className="servies-heading servies-heading2">Cloud</summary>
          <div className="line"></div>

        
          <br />
          <hr></hr>
        
          <br />
          <hr></hr>

        
          <br />
          <hr></hr>

          
          <br />
          <hr></hr>


        </details> */}
        </div>
        <br/>


      
<Footer/>
    </>
  );
}

export default Products;
